import React from 'react';
import classNames from 'classnames';
import { CategoryBasedRanking } from 'js/types/ranking';
import { AffiliateList } from 'js/components/common/Affiliate/AffiliateList';
import { AffiliateSkeletonList } from 'js/components/common/Affiliate/AffiliateSkeletonList';

export type Props = {
  ranking?: CategoryBasedRanking;
  rankNumber: number;
};

export const RankingPickupPresenter: React.FC<Props> = ({
  ranking,
  rankNumber,
}) => {
  if (ranking?.items && !(rankNumber - 1 in ranking.items)) {
    return null;
  }
  const list: CategoryBasedRanking['items'] = ranking
    ? ranking.items
        .map((item, index) => ({ ...item, rankNumber: index + 1 }))
        .slice(rankNumber - 1, rankNumber)
    : [];
  return (
    <section
      className={classNames('ranking-pickup', { 'is-loading': !ranking })}
    >
      <h2 className="ranking-pickup__heading">
        <span>
          {ranking?.categoryName
            ? `${ranking.categoryName}ランキング`
            : '\u200B'}
        </span>
      </h2>
      {ranking ? (
        <AffiliateList
          className="ranking-pickup__list"
          list={list}
          column={1}
        />
      ) : (
        <AffiliateSkeletonList
          className="ranking-pickup__list"
          count={1}
          column={1}
        />
      )}
    </section>
  );
};
