import React from 'react';
import {
  MyMenuPointProgressPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/MyMenuPointProgress/presenter';

export type Props = PresenterProps;

export const MyMenuPointProgress: React.FC<Props> = ({
  totalPoint,
  isVisible,
}) => (
  <MyMenuPointProgressPresenter totalPoint={totalPoint} isVisible={isVisible} />
);
