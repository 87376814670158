import React from 'react';
import {
  CloseButtonPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Button/CloseButton/presenter';

export type Props = PresenterProps;

export const CloseButton: React.FC<Props> = ({
  handleClose,
  size,
  position,
}) => (
  <CloseButtonPresenter
    handleClose={handleClose}
    size={size}
    position={position}
  />
);
