import React from 'react';

// URLを引き継いでログインページへ移動
export const login = (e: React.MouseEvent<HTMLElement>, url?: string) => {
  e.preventDefault();
  const form = document.createElement('form');
  form.action = '/login/';
  form.method = 'post';
  form.target = '_top';
  const input = document.createElement('input');
  input.name = 'url';
  if (url) {
    input.value = url;
  } else {
    // topはnullの可能性がある。nullの場合はECナビTOPへのURLを指定する
    input.value = top?.location.href || window.location.origin;
  }
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
};

export const logout = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  if (confirm('ログアウトしますか？')) {
    (top || window).location.href = window.location.origin + '/logout/';
  }
};
