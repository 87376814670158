import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const useAppendAd = <T extends HTMLElement>(adInnerHtml: string) => {
  const router = useRouter();
  const appendElementRef = useRef<T>(null);

  const appendAd = useCallback(() => {
    if (!appendElementRef.current) {
      return;
    }

    // 広告表示領域を削除してキレイにしてから広告を表示させる
    while (appendElementRef.current.firstChild) {
      appendElementRef.current.removeChild(appendElementRef.current.firstChild);
    }

    const fragment = document
      .createRange()
      .createContextualFragment(adInnerHtml);

    appendElementRef.current.appendChild(fragment);
  }, [adInnerHtml]);

  // 直接URLを指定してページを開いた時に広告を表示させる時の処理はこちら
  useEffect(() => {
    appendAd();
  }, [appendAd]);

  // SPAのページ遷移を行った時に広告を表示させる時の処理はこちら
  useEffect(() => {
    router.events.on('routeChangeComplete', appendAd);
    return () => {
      router.events.off('routeChangeComplete', appendAd);
    };
  }, [appendAd, router.events]);

  return { appendElementRef };
};
