/**
 * 指定された範囲のランダムな整数を返す
 *
 * @param {number} min - 生成する整数の最小値
 * @param {number} max - 生成する整数の最大値
 * @return {number} min から max までのランダムな整数
 */
export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * 与えられた配列からランダムに1つの要素を選んで返す
 *
 * @template T
 * @param {T[]} array - 要素を選ぶ対象の配列
 * @return {T} ランダムに選ばれた要素
 */
export const getRandomElement = <T>(array: T[]): T => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};
