import React from 'react';
import {
  AffiliateSkeletonItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateSkeletonItem/presenter';

export type Props = PresenterProps;

export const AffiliateSkeletonItem: React.FC<Props> = ({ className }) => (
  <AffiliateSkeletonItemPresenter className={className} />
);
