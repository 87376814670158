import React from 'react';
import {
  MyMenuPointPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/MyMenuPoint/presenter';

export type Props = PresenterProps;

export const MyMenuPoint: React.FC<Props> = ({
  totalPoint,
  expiringPoint,
  expirationDate,
  isMaintenance,
  isVisible,
}) => (
  <MyMenuPointPresenter
    totalPoint={totalPoint}
    expiringPoint={expiringPoint}
    expirationDate={expirationDate}
    isMaintenance={isMaintenance}
    isVisible={isVisible}
  />
);
