import React from 'react';
import { InformationItem } from 'js/components/specific/top/Information/InformationItem';
import classNames from 'classnames';
import { InformationElementType } from 'js/components/specific/top/Information/InformationList/type';

export type Props = {
  list: InformationElementType[];
  readInformation?: (id: string) => void;
  className?: string;
};

export const InformationListPresenter: React.FC<Props> = ({
  list,
  readInformation,
  className,
}) => (
  <ul
    className={classNames('p_information-list', className)}
    style={{ display: 'block' }}
  >
    {list.map((item) => (
      <InformationItem {...item} key={item.id} {...{ readInformation }}>
        {item.children}
      </InformationItem>
    ))}
  </ul>
);
