import React, { useEffect } from 'react';
import Head from 'next/head';
import parse from 'html-react-parser';
import head from '!!raw-loader!js/tag/advertisement/common/head.php';
import { ENVIRONMENTS } from 'js/const/env/env';
import { useRouter } from 'next/router';

export const AdvertisementCommonHeadPresenter: React.FC = () => {
  const router = useRouter();
  const removeSlots = () => {
    const googletag = window.googletag || { cmd: [], destroySlots: () => {} };
    googletag.cmd.push(function () {
      googletag.destroySlots();
    });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', removeSlots);
    return () => {
      router.events.off('routeChangeStart', removeSlots);
    };
  }, [router.events]);

  /**
   * headはPHPからも参照される広告用スクリプトのファイル
   * PHPファイルとして存在するheadをテキストファイルとして読み込み、パースしてReactコンポーネントとして扱う
   * ローカルでこの部分を消すと不正なimpが発生する可能性があるので試す場合は実タグがない場所に限る等慎重に！
   * ref: https://github.com/voyagegroup/ecnavi/pull/14330
   */
  return ENVIRONMENTS.PRODUCT_ENV === 'dev' ? null : <Head>{parse(head)}</Head>;
};
