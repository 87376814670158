import React from 'react';
import {
  FavoriteNonePresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Favorite/FavoriteNone/presenter';

export type Props = PresenterProps;

export const FavoriteNone: React.FC<Props> = ({ className }) => (
  <FavoriteNonePresenter className={className} />
);
