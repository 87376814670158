import React from 'react';
import {
  AffiliateSkeletonListPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateSkeletonList/presenter';

export type Props = PresenterProps;

export const AffiliateSkeletonList: React.FC<Props> = (props) => (
  <AffiliateSkeletonListPresenter {...props} />
);
