import { GradeLabel, GradeName } from 'js/types/grade';
import { HttpError } from 'js/utils/http/client';
import { useRequest } from 'js/hooks/useRequest';
import { useLayoutData } from 'js/hooks/useLayoutData';

export type UserInfoResponse = {
  totalPoint: number;
  nickname: string;
  grade: {
    id: number;
    name: GradeLabel;
    displayName: GradeName;
    nextGrade: GradeName;
  };
  pointLimit: {
    maintenance: boolean;
    limitPoint: number;
    nextLimitDate: string;
  };
};

export const useUserInfo = (): {
  userInfo: UserInfoResponse | undefined;
  isLogin: boolean | undefined;
  isLoading: boolean;
} => {
  // サーバーへの負荷を考慮してviewに埋め込まれたデータからユーザー情報の取得を試みて、存在しない場合に初めてAPIリクエストを行う
  // ref: https://github.com/voyagegroup/ecnavi/issues/14094
  const { layoutData, isLoadingLayoutData } = useLayoutData();
  const { data, error } = useRequest<UserInfoResponse, HttpError>(
    !isLoadingLayoutData && !layoutData
      ? ['/api/user_info/?totalPoint=1&nickname=1&grade=1&pointLimit=1']
      : null,
  );
  const isLoadingAPIData = !error && !data;

  if (!isLoadingLayoutData && layoutData) {
    return {
      userInfo: layoutData.userInfo ?? undefined,
      isLogin: !!layoutData.userInfo,
      isLoading: false,
    };
  }

  return {
    userInfo: data,
    isLogin: isLoadingAPIData ? undefined : !!data,
    isLoading: isLoadingAPIData,
  };
};
