import { EcnFramesType } from 'js/types/ecnFrames';
import { useRequestImmutable } from 'js/hooks/useRequest';
import { RankingResponse, CategoryBasedRanking } from 'js/types/ranking';
import { convertPublicationToAffiliateItem } from 'js/utils/affiliate';

type Props = {
  params: {
    categories: string[];
    ecnframe?: EcnFramesType;
  };
  isReady?: boolean;
};

export const useRankings = ({ params, isReady = true }: Props) => {
  let queryString = `categories=${params.categories
    .map(encodeURIComponent)
    .join(',')}`;
  if (params.ecnframe) {
    queryString += `&ecnframe=${params.ecnframe}`;
  }
  const { data, error } = useRequestImmutable<RankingResponse>(
    isReady ? [`/api/service/ranking/?${queryString}`] : null,
  );
  if (error || !data) {
    return { rankings: {} };
  }
  const rankings: Record<string, CategoryBasedRanking> = {};
  for (const key of Object.keys(data)) {
    rankings[key] = {
      categoryName: data[key]?.categoryName,
      items: data[key]?.items?.map((item) =>
        convertPublicationToAffiliateItem(item),
      ),
    };
  }
  return { rankings };
};
