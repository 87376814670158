import { useRouter } from 'next/router';
import { useRequest } from 'js/hooks/useRequestWithDebugParams';
import { ENVIRONMENTS } from 'js/const/env/env';
import { ClockResponse } from 'js/hooks/useTimeTravelParam/type';

export const useTimeTravelParam = (): string | undefined => {
  const router = useRouter();
  const debug = router.query.debug === '1';
  const now = router.query.now;
  const travel = router.query.travel;

  const isDebug = debug && (!!now || !!travel);
  const isProd = ENVIRONMENTS.PRODUCT_ENV === 'prod';

  // どちらも設定されていた場合はサーバー側の挙動にあわせてnowを優先させる
  const setTime = now ?? travel;

  // 無効な値が渡ってきていないか判定
  const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());
  const isInvalid = Array.isArray(setTime) || isInvalidDate(new Date(setTime));

  const fallbackData =
    !isDebug || isInvalid || isProd
      ? undefined
      : ({ type: 'TimeParameter', datetime: setTime } as ClockResponse);

  const { data } = useRequest<ClockResponse>(
    isProd ? null : '/api/debug/clock/',
    { fallbackData },
  );

  return data ? data.datetime : undefined;
};
