import React, { useState } from 'react';
import classNames from 'classnames';
import { ENVIRONMENTS, PersonalEnv, ProductEnv } from 'js/const/env/env';
import {
  convertProductEnvToEnvironmentName,
  convertPersonalEnvToPersonalName,
} from 'js/components/common/layout/ServerEnvironment/utils';

export type Props = {
  env: ProductEnv;
  personalEnv?: PersonalEnv;
  requestTime?: Date;
};

export const ServerEnvironmentPresenter: React.FC<Props> = ({
  env,
  personalEnv = '',
  requestTime,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [environmentName] = useState(
    convertProductEnvToEnvironmentName(
      env,
      personalEnv,
      ENVIRONMENTS.COMMON_BUILD,
    ),
  );
  const [personalName] = useState(
    convertPersonalEnvToPersonalName(personalEnv, ENVIRONMENTS.COMMON_BUILD),
  );

  const copyTextToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setHasCopied(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setHasCopied(false);
  };

  if (!environmentName) {
    return null;
  }

  return (
    <div
      className={classNames('server-environment', `environment-${env}`, {
        'is-hidden': isHidden,
        'has-copied': hasCopied,
      })}
    >
      <p className="server-environment__name">{environmentName}</p>
      {personalName && (
        <>
          <p
            className="server-environment__personal"
            onClick={() => void copyTextToClipboard(personalName)}
          >
            {personalName}
          </p>
        </>
      )}
      {requestTime && (
        <p className="server-environment__time">
          {requestTime.toLocaleString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        </p>
      )}
      <button
        className="server-environment__close"
        type="button"
        onClick={() => setIsHidden(true)}
      >
        &nbsp;
      </button>
    </div>
  );
};
