import { useTimeTravelParam } from 'js/hooks/useTimeTravelParam';
import { useMemo } from 'react';

export const useTimestamp = () => {
  const timeTravelParam = useTimeTravelParam();
  return useMemo(
    () =>
      timeTravelParam
        ? Math.floor(Date.parse(timeTravelParam) / 1000).toString()
        : Math.floor(Date.now() / 1000).toString(),
    [timeTravelParam],
  );
};
