import React from 'react';
import classNames from 'classnames';

export type Props = {
  className?: string;
  isSmall?: boolean;
};

export const NotificationDotPresenter: React.FC<Props> = ({
  className = '',
  isSmall = false,
}) => (
  <span
    className={classNames(
      'c_notification-dot',
      'c_position-top-right',
      { 'c_size-small': isSmall },
      className,
    )}
  />
);
