import React, { useState } from 'react';
import classNames from 'classnames';
import { CloseButton } from 'js/components/ui/Button/CloseButton';

export type InformationType = 'info' | 'warning';

export type Props = {
  id: string;
  children: React.ReactNode;
  linkUrl: string;
  type: InformationType;
  canClose: boolean;
  publishAt?: Date;
  isNoscript?: boolean;
  readInformation?: (id: string) => void;
};

export const InformationItemPresenter: React.FC<Props> = ({
  id,
  children,
  linkUrl,
  type = 'info',
  canClose,
  publishAt,
  isNoscript = false,
  readInformation,
}) => {
  const [isHiddenItem, setIsHiddenItem] = useState(false);
  const Wrapper: React.FC<{ children: React.ReactNode }> = ({
    children: itemChildren,
  }) =>
    isNoscript ? <noscript>{itemChildren}</noscript> : <>{itemChildren}</>;
  return (
    <Wrapper>
      <li
        className={classNames('p_information-list__item', `type-${type}`, {
          'is-hidden': isHiddenItem,
        })}
      >
        <a
          className="p_information-list__link"
          href={linkUrl}
          title={
            publishAt &&
            publishAt.toLocaleDateString('ja-JP', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          }
        >
          {children}
          {canClose && (
            <CloseButton
              size="small"
              position="right"
              handleClose={(e) => {
                e.preventDefault();
                readInformation?.(id);
                setIsHiddenItem(true);
              }}
            />
          )}
        </a>
      </li>
    </Wrapper>
  );
};
