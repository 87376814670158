import React from 'react';
import {
  DailyContentsItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/DailyContents/DailyContentsItem/presenter';

export type Props = PresenterProps;

export const DailyContentsItem: React.FC<Props> = (props) => (
  <DailyContentsItemPresenter {...props} />
);
