import React from 'react';
import { Button } from 'js/components/ui/Button/Button';
import { Icon } from 'js/components/ui/Icon/Icon';

export type Props = {
  className: string;
};

export const FavoriteNonePresenter: React.FC<Props> = ({ className }) => (
  <p {...{ className }}>
    お気に入りは登録されていません。
    <br />
    サービスの&nbsp;
    <Button size="small" width="fit" isInline={true} isHoverDisabled={true}>
      <Icon
        icon="symbol-favorite"
        label="お気に入りボタン"
        className="c_button__icon"
        isInline={true}
      />
    </Button>
    &nbsp;で登録できます。
  </p>
);
