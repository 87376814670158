import { useEffect } from 'react';

/**
 * このhooksはlayoutファイルで使用する
 * bodyタグ直下に展開されるポップアップ要素に対してデバイス毎のスタイルが当たるようにbodyタグにview-pc, view-spを追加する
 * 初回レンダリング時のメインコンテンツにスタイルが当たるように、layoutファイルの一番外側の要素に指定しているview-pc, view-spは削除しない
 */
export const useAddDeviceViewClassToBody = (
  deviceView: 'view-pc' | 'view-sp',
) => {
  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.add(deviceView);
    return () => body.classList.remove(deviceView);
  }, [deviceView]);
};
