import React from 'react';
import {
  PrBannerPresenter,
  Props as PresenterProps,
} from 'js/components/ui/PrBanner/presenter';

export type Props = PresenterProps;

export const PrBanner: React.FC<Props> = (props) => (
  <PrBannerPresenter {...props} />
);
