import React from 'react';
import { MyMenuPointProgress } from 'js/components/common/layout/MyMenu/MyMenuPointProgress';
import { Point } from 'js/components/common/Point/Point';

export type Props = {
  totalPoint: number;
  expiringPoint: number;
  expirationDate: string;
  isMaintenance?: boolean;
  isVisible?: boolean;
};

export const MyMenuPointPresenter: React.FC<Props> = ({
  totalPoint,
  expiringPoint,
  expirationDate,
  isMaintenance = false,
  isVisible = false,
}) => (
  <div className="new-my-menu-point">
    <p className="new-my-menu-point__title">ポイント</p>
    <Point
      className="new-my-menu-point__point"
      point={totalPoint}
      size="xx-large"
      isCondensed={true}
    />
    <p className="new-my-menu-point__expired">
      うち{isMaintenance || !expirationDate ? '次回失効予定日' : expirationDate}
      に<br />
      失効するポイントは
      {isMaintenance ? '計算中' : `${expiringPoint.toLocaleString()}pts.`}です。
    </p>
    <MyMenuPointProgress {...{ totalPoint, isVisible }} />
  </div>
);
