import React, { useState } from 'react';
import classNames from 'classnames';
import { PointPattern } from 'js/components/common/Point/PointPattern';
import { AffiliateItemType } from 'js/types/affiliate';
import { RankIcon } from 'js/components/ui/Icon/RankIcon';

export type Props = Omit<AffiliateItemType, 'createdAt'> & {
  className?: string;
  rankNumber?: number;
  children?: React.ReactNode;
};

export const AffiliateItemPresenter: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName = null,
  condition = null,
  campaignPoint,
  regularPoint,
  unit,
  pointText,
  isGradeUpCount,
  className = '',
  rankNumber,
  children,
}) => {
  const [existsImage, setExistsImage] = useState(!!imageUrl);

  return (
    <li className={classNames('p_item-list__item', 'p_item-unit', className)}>
      <a
        className="p_item-unit__link c_reset c_blocklink c_hover-pop"
        href={linkUrl}
      >
        {rankNumber && <RankIcon rankNumber={rankNumber} position="top-left" />}
        <p className="p_item-unit__image-wrapper">
          {existsImage ? (
            <img
              className="p_item-unit__image"
              src={imageUrl}
              alt=""
              onError={() => setExistsImage(false)}
            />
          ) : (
            <span className="p_item-unit__no-image">画像なし</span>
          )}
        </p>
        {itemName && <p className="p_item-unit__name">{itemName}</p>}
        {condition && <p className="p_item-unit__condition">{condition}</p>}
        <PointPattern
          baseClassName="p_item-unit"
          {...{ campaignPoint, regularPoint, unit, pointText, isGradeUpCount }}
        />
        {children}
      </a>
    </li>
  );
};
