import useSWR, { Key, SWRConfiguration, SWRResponse } from 'swr';
import { client } from 'js/utils/http/client';
import { AxiosRequestConfig } from 'axios';
import { ENVIRONMENTS } from 'js/const/env/env';

/**
 * clientをfetcherとするswrのラッパーhook
 *
 * swrはデフォルトではエラーが発生する限りリトライを繰り返す
 * clientは独自でリトライ制御を行っているためリトライ処理はswrの機能ではなくclientに任せる
 *
 * @param {Key} key
 * @param {SWRConfiguration<Data, Error>} config
 * @return {SWRResponse<Data, Error>}
 */
export const useRequest = <Data = unknown, Error = unknown>(
  key: Key,
  config: SWRConfiguration<Data, Error> = {},
): SWRResponse<Data, Error> =>
  useSWR<Data, Error>(
    key,
    (url: string, requestConfig?: AxiosRequestConfig) =>
      client.get<Data>(url, requestConfig).then((res) => res.data),
    { ...config, shouldRetryOnError: false },
  );

/**
 * immutableなリソースの場合に自動再検証を無効にするヘルパーフック
 * https://swr.vercel.app/ja/docs/revalidation#%E8%87%AA%E5%8B%95%E5%86%8D%E6%A4%9C%E8%A8%BC%E3%81%AE%E7%84%A1%E5%8A%B9%E5%8C%96
 * https://github.com/vercel/swr/blob/be6958c2556126ab902eae9a4031b4353f7d8234/immutable/index.ts
 *
 * @param {Key} key
 * @param {SWRConfiguration<Data, Error>} config
 * @return {SWRResponse<Data, Error>}
 */
export const useRequestImmutable = <Data = unknown, Error = unknown>(
  key: Key,
  config: SWRConfiguration<Data, Error> = {},
): SWRResponse<Data, Error> => {
  config.revalidateOnFocus = false;
  config.revalidateIfStale = false;
  config.revalidateOnReconnect = false;
  return useRequest<Data, Error>(key, config);
};

/**
 * SSGを動作させつつ、本番環境以外はマウント時に再リクエストを行うためのHook
 * (タイムトラベル etc.を機能させるために作成)
 *
 * @param {Key} key
 * @param {Data} fallbackData
 * @return {SWRResponse<Data, Error>}
 */
export const useStaticDataRequest = <Data = unknown, Error = unknown>(
  key: Key,
  fallbackData: Data,
): SWRResponse<Data, Error> => {
  const isProduction = ENVIRONMENTS.PRODUCT_ENV === 'prod';
  return useRequestImmutable<Data, Error>(key, {
    fallbackData,
    revalidateOnMount: !isProduction,
  });
};

/**
 * API リクエストを並列実行する
 *
 * @param {string[]} urls
 * @param {SWRConfiguration<{[I in keyof Data]: PromiseSettledResult<Data[I]>}, Error>} config
 * @returns {SWRResponse<{[I in keyof Data]: PromiseSettledResult<Data[I]>}, Error>}
 */
export const useRequestAllSettled = <
  Data extends readonly unknown[],
  Error = unknown,
>(
  urls: string[],
  config: SWRConfiguration<
    { [I in keyof Data]: PromiseSettledResult<Data[I]> },
    Error
  > = {},
) => {
  const fetcher = (...urlsForFetcher: string[]) => {
    const f = (url: string) =>
      client.get<keyof Data>(url).then((res) => res.data);
    return Promise.allSettled(urlsForFetcher.map((url) => f(url)));
  };

  return useSWR<
    { [I in keyof Data]: PromiseSettledResult<Data[I]> },
    Error,
    string[]
  >(
    urls,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fetcher,
    {
      ...config,
      shouldRetryOnError: false,
    },
  );
};
