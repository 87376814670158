import { ENVIRONMENTS } from 'js/const/env/env';

export const parsePathParams = (
  relativeUrl: string,
): {
  path: string;
  params: URLSearchParams;
} => {
  // URLオブジェクトがパスの取得やクエリパラメータの取得に使えるが，
  // ベースURLが必須のためダミーを設定している
  const url = new URL(relativeUrl, /* ダミー */ 'https://example.com');
  const path = url.pathname;
  const params = new URLSearchParams(url.search);

  return { path, params };
};

// debug用のパラメータ以外をフィルタする
const throughOnlyDebugParams = (params: URLSearchParams): URLSearchParams => {
  const debugParams = new URLSearchParams();
  params.forEach((value, paramKey) => {
    if (paramKey === 'now' || paramKey === 'debug') {
      debugParams.append(paramKey, value);
    }
  });
  return debugParams;
};

export const buildUrlWithDebugParams = (
  url: string,
  pageParams: URLSearchParams,
): string => {
  const isProduction = ENVIRONMENTS.PRODUCT_ENV === 'prod';
  const { path: requestPath, params: requestParams } = parsePathParams(url);

  // リクエストを再構築
  const params = new URLSearchParams();
  // 本番では時間操作は無効
  const debugParam = isProduction
    ? new URLSearchParams()
    : throughOnlyDebugParams(pageParams);
  requestParams.forEach((value, paramKey) => params.append(paramKey, value));
  debugParam.forEach((value, paramKey) => params.append(paramKey, value));

  const rebuildParams = params.toString();
  const rebuildKey = !rebuildParams
    ? requestPath
    : requestPath + '?' + rebuildParams;

  return rebuildKey;
};
