import React from 'react';
import { TrendingWordType } from 'js/components/common/layout/TrendingWord/type';

export type Props = {
  trendingWordList?: TrendingWordType[];
  isTitleVisible?: boolean;
};

export const TrendingWordPresenter: React.FC<Props> = ({
  trendingWordList,
  isTitleVisible = false,
}) => (
  <>
    {trendingWordList && trendingWordList.length > 0 && (
      <div className="trending-word">
        {isTitleVisible && <p className="trending-word__heading">注目ワード</p>}
        <ul className="trending-word__list">
          {trendingWordList.map((item) => (
            <li className="trending-word__item" key={item.name}>
              <a
                className="trending-word__link c_reset c_blocklink"
                href={item.linkUrl}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )}
  </>
);
