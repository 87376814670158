import React from 'react';
import classNames from 'classnames';
import { AffiliateItem } from 'js/components/common/Affiliate/AffiliateItem';
import { AffiliateItemType } from 'js/types/affiliate';
import { ColumnType } from 'js/types/gridList';

export type Props = {
  list: AffiliateItemType[];
  isFadeIn?: boolean;
  column?: Omit<ColumnType, 'auto'>;
  isGrid?: boolean;
  className?: string;
};

export const AffiliateListPresenter: React.FC<Props> = ({
  list,
  isFadeIn = false,
  column,
  isGrid,
  className,
}) => (
  <ul
    className={classNames(
      className,
      'c_contents-group',
      'p_item-list__list',
      { [`column-${String(column)}`]: column },
      'u_bg-white',
      { 'is-fade-in': isFadeIn },
      { 'is-grid': isGrid },
    )}
  >
    {list.map((item) => (
      <AffiliateItem {...item} key={item.linkUrl} />
    ))}
  </ul>
);
