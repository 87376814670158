import React from 'react';
import {
  ServerEnvironmentPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/ServerEnvironment/presenter';

export type Props = PresenterProps;

export const ServerEnvironment: React.FC<Props> = ({
  env,
  personalEnv,
  requestTime,
}) => (
  <ServerEnvironmentPresenter
    env={env}
    personalEnv={personalEnv}
    requestTime={requestTime}
  />
);
