import { useEffect, useState } from 'react';

export const useReadyWhenShow = (isShow: boolean) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (isShow) {
      setIsReady(true);
    }
  }, [isShow]);
  return { isReady };
};
