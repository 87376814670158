import React from 'react';
import {
  DailyContentsTitlePresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/DailyContents/DailyContentsTitle/presenter';

export type Props = PresenterProps;

export const DailyContentsTitle: React.FC<Props> = (props) => (
  <DailyContentsTitlePresenter {...props} />
);
