import React from 'react';
import {
  MyMenuShortcutPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/MyMenuShortcut/presenter';

export type Props = PresenterProps;

export const MyMenuShortcut: React.FC<Props> = ({ list }) => (
  <MyMenuShortcutPresenter list={list} />
);
