import React from 'react';
import {
  DailyContentsSkeletonListPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/DailyContents/DailyContentsSkeletonList/presenter';

export type Props = PresenterProps;

export const DailyContentsSkeletonList: React.FC<Props> = (props) => (
  <DailyContentsSkeletonListPresenter {...props} />
);
