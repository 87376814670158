import React from 'react';
import {
  PointPresenter,
  Props as PresenterProps,
} from 'js/components/common/Point/Point/presenter';

export type Props = PresenterProps;

export const Point: React.FC<Props> = ({ ...props }) => (
  <PointPresenter {...props} />
);
