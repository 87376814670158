import React from 'react';
import classNames from 'classnames';
import {
  Unit,
  Size,
  SizeType,
  Style,
} from 'js/components/common/Point/Point/type';

export type Props = {
  point: number;
  unit?: Unit;
  size?: Size;
  sizeType?: SizeType;
  isCondensed?: boolean;
  style?: Style;
  prefix?: string;
  className?: string;
};

export const PointPresenter: React.FC<Props> = ({
  unit = 'amount',
  size = 'medium',
  point,
  sizeType = 'absolute',
  isCondensed = false,
  style = 'emphasis',
  prefix,
  className,
}) => (
  <span
    className={classNames(
      className,
      { c_point: unit === 'amount' },
      { c_point_rate: unit === 'rate' },
      { [`c_${size.replace('-', '_')}`]: sizeType === 'absolute' },
      { [`u_size-${size}`]: sizeType === 'relative' },
      { c_condensed: isCondensed },
      { c_strikethrough: style === 'strikethrough' },
      { c_reset: style === 'normal' },
    )}
  >
    {prefix}
    {point
      .toLocaleString()
      .split(',')
      .map((splitNumberText, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="c_point__comma">,</span>}
          {splitNumberText}
        </React.Fragment>
      ))}
  </span>
);
