import { SWRConfiguration, SWRResponse } from 'swr';
import {
  parsePathParams,
  buildUrlWithDebugParams,
} from 'js/hooks/useRequestWithDebugParams/utils';
import { useEffect, useState } from 'react';
import * as defaultUseRequest from 'js/hooks/useRequest';
import { isNullish } from 'js/utils/helper';

/**
 * ページに渡されたパラメータをapiにも渡すためのHook群
 * デバッグ時に時間操作関係のパラメータのみ渡す
 */

/**
 * 現在のパス（クエリ文字列も含む）を取得する
 */
const useCurrentPath = (): string => {
  const [currentPath, setCurrentPath] = useState<string>('/');

  useEffect(() => {
    setCurrentPath(window.location.pathname + window.location.search);
  }, []);

  return currentPath;
};

/**
 * clientをfetcherとするswrのラッパーhook
 * @see {@link defaultUseRequest.useRequest}
 *
 * @param {string} key
 * @param {SWRConfiguration<Data, Error>} config
 * @return {SWRResponse<Data, Error>}
 */
export const useRequest = <Data = unknown, Error = unknown>(
  key: string | null,
  config: SWRConfiguration<Data, Error> = {},
): SWRResponse<Data, Error> => {
  const currentPath = useCurrentPath();
  const { params: pageParams } = parsePathParams(currentPath);
  const rebuildKey = isNullish(key)
    ? null
    : buildUrlWithDebugParams(key, pageParams);

  return defaultUseRequest.useRequest<Data, Error>(rebuildKey, config);
};

/**
 * immutableなリソースの場合に自動再検証を無効にするヘルパーフック
 * @see {@link defaultUseRequest.useRequestImmutable}
 *
 * @param {string} key
 * @param {SWRConfiguration<Data, Error>} config
 * @return {SWRResponse<Data, Error>}
 */
export const useRequestImmutable = <Data = unknown, Error = unknown>(
  key: string | null,
  config: SWRConfiguration<Data, Error> = {},
): SWRResponse<Data, Error> => {
  const currentPath = useCurrentPath();
  const { params: pageParams } = parsePathParams(currentPath);
  const rebuildKey = isNullish(key)
    ? null
    : buildUrlWithDebugParams(key, pageParams);

  return defaultUseRequest.useRequestImmutable<Data, Error>(rebuildKey, config);
};

/**
 * SSGを動作させつつ、本番環境以外はマウント時に再リクエストを行うためのHook
 * @see {@link defaultUseRequest.useStaticDataRequest}
 *
 * @param {string} key
 * @param {Data} fallbackData
 * @return {SWRResponse<Data, Error>}
 */
export const useStaticDataRequest = <Data = unknown, Error = unknown>(
  key: string | null,
  fallbackData: Data,
): SWRResponse<Data, Error> => {
  const currentPath = useCurrentPath();
  const { params: pageParams } = parsePathParams(currentPath);
  const rebuildKey = isNullish(key)
    ? null
    : buildUrlWithDebugParams(key, pageParams);

  return defaultUseRequest.useStaticDataRequest<Data, Error>(
    rebuildKey,
    fallbackData,
  );
};

/**
 * API リクエストを並列実行する
 *
 * @param {string[]} urls
 * @param {SWRConfiguration<{[I in keyof Data]: PromiseSettledResult<Data[I]>}, Error>} config
 * @returns {SWRResponse<{[I in keyof Data]: PromiseSettledResult<Data[I]>}, Error>}
 */
export const useRequestAllSettled = <
  Data extends readonly unknown[],
  Error = unknown,
>(
  urls: string[],
  config: SWRConfiguration<
    { [I in keyof Data]: PromiseSettledResult<Data[I]> },
    Error
  > = {},
) => {
  const currentPath = useCurrentPath();
  const { params: pageParams } = parsePathParams(currentPath);
  const rebuildUrls = urls.map((url) =>
    buildUrlWithDebugParams(url, pageParams),
  );

  return defaultUseRequest.useRequestAllSettled<Data, Error>(
    rebuildUrls,
    config,
  );
};
