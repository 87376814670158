import React from 'react';
import classNames from 'classnames';
import { ColumnType } from 'js/types/gridList';
import { AffiliateSkeletonItem } from 'js/components/common/Affiliate/AffiliateSkeletonItem';
import { range } from 'js/utils/helper';

export type Props = {
  className?: string;
  count: number;
  column: Omit<ColumnType, 'auto'>;
  isGrid?: boolean;
};

export const AffiliateSkeletonListPresenter: React.FC<Props> = ({
  className,
  count,
  column,
  isGrid,
}) => (
  <ul
    className={classNames(
      'c_contents-group',
      'p_item-list__list',
      { [`column-${String(column)}`]: column },
      'u_bg-white',
      { 'is-grid': isGrid },
      className,
    )}
  >
    {range(1, count).map((item) => (
      <AffiliateSkeletonItem key={item} />
    ))}
  </ul>
);
