import React from 'react';
import {
  InformationListPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Information/InformationList/presenter';

export type Props = PresenterProps;

export const InformationList: React.FC<Props> = ({
  list,
  readInformation,
  className,
}) => <InformationListPresenter {...{ list, readInformation, className }} />;
