import React from 'react';
import classNames from 'classnames';
import { ColumnType } from 'js/types/gridList';
import { range } from 'js/utils/helper';

export type Props = {
  count: number;
  column: ColumnType;
};

export const DailyContentsSkeletonListPresenter: React.FC<Props> = ({
  count,
  column,
}) => (
  <ul
    className={classNames(
      'daily-contents-window__list',
      'u_grid',
      `u_column-${column}`,
      'u_bg-white',
    )}
  >
    {range(1, count).map((num) => (
      <li
        className="daily-contents-window__skeleton-item u_grid__item"
        key={num}
      >
        <span className="daily-contents-window__skeleton-image">&nbsp;</span>
        <span className="daily-contents-window__skeleton-name">&nbsp;</span>
      </li>
    ))}
  </ul>
);
