export const boolResult = (v: boolean | (() => boolean)): boolean =>
  typeof v === 'function' ? v() : v;

/**
 * start ~ end の間で連続した数値を持つ配列を返す
 *
 * @param start - 開始値
 * @param end - 終了値
 * @param step - 増分
 * @returns start ~ end の間で連続した数値を持つ配列
 */
export const range = (start: number, end: number, step = 1) => {
  const array: number[] = [];
  for (let i = start; i <= end; i += step) {
    array.push(i);
  }
  return array;
};

export const convertLowerCamelCaseToHyphenSeparatedCase = (
  lowerCamelCase: string,
) =>
  lowerCamelCase
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();

export const isNullish = (value: unknown): value is null | undefined => {
  return value === null || value === undefined;
};
