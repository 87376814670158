import React from 'react';
import classNames from 'classnames';

export type Props = {
  count: number;
  className?: string;
  hasBorder?: boolean;
};

export const BadgePresenter: React.FC<Props> = ({
  count,
  className = '',
  hasBorder = false,
}) =>
  count > 0 ? (
    <span
      className={classNames(className, 'c_badge', {
        'c_has-border': hasBorder,
      })}
    >
      {count > 99 ? '99+' : count}
    </span>
  ) : null;
