import React from 'react';
import {
  DailyContentsListPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/DailyContents/DailyContentsList/presenter';

export type Props = PresenterProps;

export const DailyContentsList: React.FC<Props> = (props) => {
  return <DailyContentsListPresenter {...props} />;
};
