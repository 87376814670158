import React from 'react';
import { GradeLabel } from 'js/types/grade';

export type Props = {
  gradeLabel: GradeLabel;
  className?: string;
  width?: number;
  height?: number;
};

export const GradeIconPresenter: React.FC<Props> = ({
  gradeLabel,
  className = '',
  width,
  height,
}) => (
  <img
    className={className}
    src={`https://ecnavi.jp/global/img/icon/grade/icon_grade_${gradeLabel}.svg`}
    alt=""
    width={width}
    height={height}
  />
);
