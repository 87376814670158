import React from 'react';
import {
  RankIconPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Icon/RankIcon/presenter';

export type Props = PresenterProps;

export const RankIcon: React.FC<Props> = (props) => (
  <RankIconPresenter {...props} />
);
