import React from 'react';
import { Button } from 'js/components/ui/Button/Button';

export const MyMenuPointButtonPresenter: React.FC = () => (
  <div className="new-my-menu-button">
    <Button
      className="new-my-menu-button__button"
      href="/mypage/ex_select/"
      isPrimary={true}
    >
      ポイント交換
    </Button>
    <Button
      className="new-my-menu-button__button"
      href="/mypage/point_history/"
    >
      ポイント履歴
    </Button>
  </div>
);
