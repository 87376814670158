import React from 'react';
import {
  SearchPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Form/Search/presenter';

export type Props = PresenterProps;

export const Search: React.FC<Props> = ({
  action,
  name,
  defaultValue,
  maxLength,
  placeholder,
  hiddenInputs,
  hasButton,
  baseClassName,
}) => (
  <SearchPresenter
    action={action}
    name={name}
    defaultValue={defaultValue}
    maxLength={maxLength}
    placeholder={placeholder}
    hiddenInputs={hiddenInputs}
    hasButton={hasButton}
    baseClassName={baseClassName}
  />
);
