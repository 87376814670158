import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export type HiddenInputTypes = {
  name: string;
  value: string;
};

export interface Props {
  action?: string;
  name?: string;
  defaultValue?: string;
  maxLength?: number;
  placeholder?: string;
  hiddenInputs?: HiddenInputTypes[];
  hasButton?: boolean;
  baseClassName?: string;
}

export const SearchPresenter: React.FC<Props> = ({
  action,
  name,
  defaultValue = '',
  maxLength,
  placeholder,
  hiddenInputs = [],
  hasButton,
  baseClassName,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <section className={baseClassName}>
      <form
        data-testid="TestId__SEARCH_FORM"
        className={classNames(
          { c_search: hasButton },
          {
            [`${baseClassName}__form`]: baseClassName,
          },
        )}
        method="get"
        {...{ action }}
      >
        <input
          className={classNames(
            hasButton ? 'c_search__input' : 'c_search_simple',
            {
              [`${baseClassName}__input`]: baseClassName,
            },
          )}
          type="search"
          role="searchbox"
          {...{ name, maxLength, placeholder }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        {hiddenInputs.map((hiddenInput) => (
          <input type="hidden" key={hiddenInput.name} {...hiddenInput} />
        ))}
        {hasButton && (
          <button
            className={classNames(
              'c_search__button',
              `${baseClassName}__button`,
            )}
            type="submit"
          >
            検索
          </button>
        )}
      </form>
    </section>
  );
};
