import React from 'react';
import {
  AffiliateListPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateList/presenter';

export type Props = PresenterProps;

export const AffiliateList: React.FC<Props> = (props) => (
  <AffiliateListPresenter {...props} />
);
