import React, { useMemo } from 'react';
import { RankingPickupPresenter } from 'js/components/common/layout/RankingPickup/presenter';
import isChromatic from 'chromatic/isChromatic';
import { getRandomElement, getRandomInt } from 'js/utils/random';
import { useReadyWhenShow } from 'js/hooks/useReadyWhenShow';
import { useRankings } from 'js/hooks/useRankings';
import { EcnFrames } from 'js/const/ecnFrames';
import { CategoryBasedRanking } from 'js/types/ranking';
import { MAX_RANK_NUMBER } from 'js/components/common/layout/RankingPickup/const';

export type Props = {
  categories: string[];
  isShow: boolean;
};

export const RankingPickup: React.FC<Props> = ({ categories, isShow }) => {
  const category = useMemo(() => {
    return isChromatic() ? categories[0] : getRandomElement(categories);
  }, [categories]);
  const { isReady } = useReadyWhenShow(isShow);
  const { rankings } = useRankings({
    params: {
      categories: [category],
      ecnframe: EcnFrames.RANKING_PICKUP,
    },
    isReady,
  });
  const ranking: CategoryBasedRanking | undefined =
    rankings[category] || undefined;
  const itemCount = ranking?.items?.length || 0;
  const rankNumber = useMemo(() => {
    if (itemCount > 0) {
      const maxRankNumber: number =
        itemCount < MAX_RANK_NUMBER ? itemCount : MAX_RANK_NUMBER;
      return isChromatic() ? 1 : getRandomInt(1, maxRankNumber);
    }
    return 1;
  }, [itemCount]);

  return <RankingPickupPresenter ranking={ranking} rankNumber={rankNumber} />;
};
