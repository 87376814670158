import { useRequestImmutable } from 'js/hooks/useRequest';
import { AffiliateItemType } from 'js/types/affiliate';

export type FavoriteItemsResponse = {
  name: string;
  pointValue?: number;
  pointRate?: number;
  pointCondition: string;
  imageUrl: string;
  linkUrl: string;
  campaignPoint?: number;
  campaignPointRate?: number;
  isGradeUpCount: boolean;
  createdAt: string;
};

type Props = {
  isReady: boolean;
};

export const useFavoriteItems = (
  options = {
    isReady: true,
  } as Partial<Props>,
) => {
  const { isReady } = options;

  // MEMO:
  // 現状、再検証を無効にしているため、いいねを押した同一画面(画面遷移をしない）でお気に入りを開くといいねが反映されていない。
  // トップページ以外のReact化が進んだタイミングで修正する。
  const { data } = useRequestImmutable<FavoriteItemsResponse[]>(
    isReady ? ['/api/favorite_items/'] : null,
  );

  const favoriteItems = data
    ? data.map(
        (item): AffiliateItemType => ({
          linkUrl: item.linkUrl,
          imageUrl: item.imageUrl,
          itemName: item.name,
          condition: item.pointCondition,
          campaignPoint: (() => {
            if (item.campaignPoint) {
              return item.campaignPoint;
            }
            if (item.campaignPointRate) {
              return item.campaignPointRate;
            }
            return undefined;
          })(),
          regularPoint: (() => {
            if (item.pointValue) {
              return item.pointValue;
            }
            if (item.pointRate) {
              return item.pointRate;
            }
            return 0;
          })(),
          unit: item.pointValue ? 'amount' : 'rate',
          isGradeUpCount: item.isGradeUpCount,
          createdAt: new Date(item.createdAt),
        }),
      )
    : [];

  return { favoriteItems };
};
