import React from 'react';
import {
  BadgePresenter,
  Props as PresenterProps,
} from 'js/components/ui/Notification/Badge/presenter';

export type Props = PresenterProps;

export const Badge: React.FC<Props> = ({ count, className, hasBorder }) => (
  <BadgePresenter count={count} className={className} hasBorder={hasBorder} />
);
