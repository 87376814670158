import React from 'react';
import {
  GradeIconPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Icon/GradeIcon/presenter';

export type Props = PresenterProps;

export const GradeIcon: React.FC<Props> = ({
  gradeLabel,
  className,
  width,
  height,
}) => (
  <GradeIconPresenter
    gradeLabel={gradeLabel}
    className={className}
    width={width}
    height={height}
  />
);
