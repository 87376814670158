import React from 'react';
import classNames from 'classnames';
import { Point, Props as PointProps } from 'js/components/common/Point/Point';
import { Size } from 'js/components/common/Point/Point/type';

export type Props = Omit<
  PointProps,
  'point' | 'size' | 'style' | 'className'
> & {
  regularPoint: number;
  campaignPoint?: number;
  pointText?: string;
  beforeSize?: Size;
  afterSize?: Size;
  isGradeUpCount?: boolean;
  baseClassName?: string;
};

export const PointPatternPresenter: React.FC<Props> = ({
  regularPoint,
  campaignPoint,
  pointText,
  beforeSize = 'small',
  afterSize = 'x-large',
  unit,
  sizeType,
  isCondensed,
  isGradeUpCount,
  baseClassName = 'point-pattern',
}) => (
  <div className={`${baseClassName}__point`}>
    {pointText ? (
      <span
        className={classNames('c_point-text', `${baseClassName}__point-text`)}
      >
        {pointText}
      </span>
    ) : regularPoint ? (
      <>
        {campaignPoint && (
          <>
            <Point
              className={`${baseClassName}__point-before`}
              point={regularPoint}
              size={beforeSize}
              style="strikethrough"
              {...{ unit, sizeType, isCondensed }}
            />
            <span className={`${baseClassName}__point-separation`}>&nbsp;</span>
          </>
        )}
        <Point
          className={`${baseClassName}__point-after`}
          point={campaignPoint || regularPoint}
          size={afterSize}
          {...{ unit, sizeType, isCondensed }}
        />
      </>
    ) : isGradeUpCount ? (
      <span className={classNames('c_grade-up', `${baseClassName}__grade-up`)}>
        グレードアップ対象
      </span>
    ) : (
      regularPoint === 0 && (
        <span
          className={classNames('c_point-none', `${baseClassName}__point-none`)}
        >
          ポイント対象外
        </span>
      )
    )}
  </div>
);
