import {
  AffiliateItemResponse,
  AffiliateItemType,
  PublicationItemResponse,
} from 'js/types/affiliate';
import { RecommendItemResponse } from 'js/hooks/pages/useDynamicData/type';

const POINT_TYPE = {
  FIXED_AMOUNT: '1',
  FIXED_RATE: '2',
} as const;

export const convertToAffiliateItem = (
  item: AffiliateItemResponse,
): AffiliateItemType => {
  const linkUrl =
    (() => {
      if (item.LINK) {
        return item.LINK;
      }
      if (!item.ITEM) {
        return '';
      }
      return item.ITEM?.links?.show;
    })() || '';
  const imageUrl =
    (() => {
      if (item.IMAGE_URL) {
        return item.IMAGE_URL;
      }
      if (!item.ITEM) {
        return '';
      }
      return item.ITEM?.imageUrl;
    })() || '';
  const itemName = (() => {
    if (item.NAME) {
      return item.NAME;
    }
    if (!item.ITEM) {
      return '';
    }
    return item.ITEM?.name;
  })();
  const condition = (() => {
    if (item.POINT_CONDITION) {
      return item.POINT_CONDITION;
    }
    if (!item.ITEM) {
      return '';
    }
    return item.ITEM?.pointCondition;
  })();
  const campaignPoint = (() => {
    if (!item.ITEM || !item.ITEM?.pointUp) {
      return undefined;
    }
    if (Number.isFinite(item.ITEM?.pointUp?.pointValue)) {
      return item.ITEM?.pointUp?.pointValue;
    }
    if (Number.isFinite(item.ITEM?.pointUp?.pointRate)) {
      return item.ITEM?.pointUp?.pointRate;
    }
  })();
  const regularPoint =
    (() => {
      if (!item.ITEM) {
        return undefined;
      }
      if (Number.isFinite(item.ITEM?.pointValue)) {
        return item.ITEM?.pointValue;
      }
      if (Number.isFinite(item.ITEM?.pointRate)) {
        return item.ITEM?.pointRate;
      }
    })() || 0;
  const unit = (() => {
    if (!item.ITEM) {
      return undefined;
    }
    if (item.ITEM?.pointType === POINT_TYPE.FIXED_AMOUNT) {
      return 'amount';
    }
    if (item.ITEM?.pointType === POINT_TYPE.FIXED_RATE) {
      return 'rate';
    }
  })();
  const pointText = item.POINT ?? undefined;
  const isGradeUpCount =
    (() => {
      if (!item.ITEM) {
        return false;
      }
      return item.ITEM?.isEcmc;
    })() || false;
  const createdAt = (() => {
    if (!item.ITEM) {
      return undefined;
    }
    return item.ITEM?.createdAt ? new Date(item.ITEM.createdAt) : undefined;
  })();
  return {
    linkUrl,
    imageUrl,
    itemName,
    condition,
    campaignPoint,
    regularPoint,
    unit,
    pointText,
    isGradeUpCount,
    createdAt,
  };
};

export const convertPublicationToAffiliateItem = (
  item: PublicationItemResponse,
): AffiliateItemType => {
  const linkUrl = item.linkUrl;
  const imageUrl = item.imageUrl;
  const itemName = item.itemName;
  const condition = item.condition;
  const campaignPoint = (() => {
    if (Number.isFinite(item.campaignPoint)) {
      return item.campaignPoint;
    }
    if (Number.isFinite(item.campaignPointRate)) {
      return item.campaignPointRate;
    }
  })();
  const regularPoint =
    (() => {
      if (Number.isFinite(item.regularPoint)) {
        return item.regularPoint;
      }
      if (Number.isFinite(item.regularPointRate)) {
        return item.regularPointRate;
      }
    })() || 0;
  const unit = (() => {
    if (item.regularPoint) {
      return 'amount';
    }
    if (item.regularPointRate) {
      return 'rate';
    }
  })();
  const pointText = undefined;
  const isGradeUpCount = item.isGradeUpCount;
  const createdAt = undefined;
  return {
    linkUrl,
    imageUrl,
    itemName,
    condition,
    campaignPoint,
    regularPoint,
    unit,
    pointText,
    isGradeUpCount,
    createdAt,
  };
};

export const convertRecommendToAffiliateItem = (
  item: RecommendItemResponse,
): AffiliateItemType => {
  const linkUrl = item.links.show;
  const imageUrl = item.imageUrl;
  const itemName = item.name;
  const condition = item.pointCondition;
  const campaignPoint = (() => {
    if (!item.pointUp) {
      return undefined;
    }
    if (Number.isFinite(item.pointUp.pointValue)) {
      return item.pointUp.pointValue;
    }
    if (Number.isFinite(item.pointUp.pointRate)) {
      return item.pointUp.pointRate;
    }
  })();
  const regularPoint =
    (() => {
      if (Number.isFinite(item.pointValue)) {
        return item.pointValue;
      }
      if (Number.isFinite(item.pointRate)) {
        return item.pointRate;
      }
    })() || 0;
  const unit = (() => {
    if (item.pointValue) {
      return 'amount';
    }
    if (item.pointRate) {
      return 'rate';
    }
  })();
  const pointText = undefined;
  const isGradeUpCount = false;
  const createdAt = item.createdAt ? new Date(item.createdAt) : undefined;
  return {
    linkUrl,
    imageUrl,
    itemName,
    condition,
    campaignPoint,
    regularPoint,
    unit,
    pointText,
    isGradeUpCount,
    createdAt,
  };
};
