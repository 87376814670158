import React from 'react';
import {
  InformationItemPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Information/InformationItem/presenter';

export type Props = PresenterProps;

export const InformationItem: React.FC<Props> = ({
  id,
  children,
  linkUrl,
  type,
  canClose,
  publishAt,
  isNoscript,
  readInformation,
}) => (
  <InformationItemPresenter
    id={id}
    linkUrl={linkUrl}
    type={type}
    canClose={canClose}
    publishAt={publishAt}
    isNoscript={isNoscript}
    readInformation={readInformation}
  >
    {children}
  </InformationItemPresenter>
);
