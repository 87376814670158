import React from 'react';
import {
  MyMenuShortcutItem,
  Props as MyMenuShortcutItemProps,
} from 'js/components/common/layout/MyMenu/MyMenuShortcutItem';

export type Props = {
  list: MyMenuShortcutItemProps[];
};

export const MyMenuShortcutPresenter: React.FC<Props> = ({ list }) => (
  <ul className="new-my-menu-shortcut u_grid u_column-4 u_border-none">
    {list.map((item) => (
      <MyMenuShortcutItem {...item} key={item.name} />
    ))}
  </ul>
);
