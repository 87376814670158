import React, { useEffect, useState } from 'react';
import { InformationElementType } from 'js/components/specific/top/Information/InformationList/type';
import { Icon } from 'js/components/ui/Icon/Icon';

export const useInformationList = () => {
  const [informationList, setInformationList] = useState<
    InformationElementType[]
  >([
    {
      id: 'javascript_disabled',
      children: (
        <>
          <Icon
            icon="symbol-important"
            className="p_information__icon"
            isInline={true}
          />
          JavaScriptが無効になっています。
          <br />
          ポイント獲得するには有効にしてください。
        </>
      ),
      linkUrl: '/help/etc/etc_environment/137/',
      type: 'warning',
      canClose: false,
      isNoscript: true,
    },
  ]);
  useEffect(() => {
    const isCookieEnable = () => {
      // see https://github.com/js-cookie/js-cookie/issues/341 , https://github.com/Modernizr/Modernizr/blob/5e3f359bfc9aa511543ece60bd8a6ea8aa7defd3/feature-detects/cookies.js#L16-L35
      try {
        // Create cookie
        document.cookie = 'cookietest=1';
        const ret = document.cookie.indexOf('cookietest=') != -1;
        // Delete cookie
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
        return ret;
      } catch (e) {
        return false;
      }
    };
    if (!isCookieEnable()) {
      setInformationList((list) => [
        ...list,
        {
          id: 'cookie_disabled',
          children: (
            <>
              <Icon
                icon="symbol-important"
                className="p_information__icon"
                isInline={true}
              />
              Cookieが無効になっています。
              <br />
              ポイント獲得するには有効にしてください。
            </>
          ),
          linkUrl: '/help/etc/etc_environment/137/',
          type: 'warning',
          canClose: false,
        },
      ]);
    }
  }, []);

  return informationList;
};
