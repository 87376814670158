import React from 'react';
import {
  AffiliateItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateItem/presenter';

export type Props = PresenterProps;

export const AffiliateItem: React.FC<Props> = ({ children, ...props }) => (
  <AffiliateItemPresenter {...props}>{children}</AffiliateItemPresenter>
);
