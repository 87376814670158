import React from 'react';
import { RankLabel } from 'js/types/ranking';
import classNames from 'classnames';

export type Props = {
  rankNumber: number;
  position?: 'top-left';
};

export const RankIconPresenter: React.FC<Props> = ({
  rankNumber,
  position,
}) => {
  const rankLabel: RankLabel =
    rankNumber === 1
      ? 'gold'
      : rankNumber === 2
        ? 'silver'
        : rankNumber === 3
          ? 'bronze'
          : 'others';

  return (
    <span
      className={classNames(
        'c_rank-icon',
        { 'position-top-left': position === 'top-left' },
        `rank-${rankLabel}`,
      )}
    >
      {rankNumber}
    </span>
  );
};
