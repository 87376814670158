import React from 'react';
import classNames from 'classnames';

export type Props = {
  className?: string;
};

export const AffiliateSkeletonItemPresenter: React.FC<Props> = ({
  className,
}) => (
  <li className={classNames('p_item-list__item', 'p_item-unit', className)}>
    <span className="p_item-unit__link">
      <span className="p_item-unit__image-wrapper">
        <span className="p_item-unit__skeleton-image" />
      </span>
      <span className="p_item-unit__skeleton-name" />
      <span className="p_item-unit__skeleton-condition" />
    </span>
  </li>
);
