import { PersonalEnv, ProductEnv } from 'js/const/env/env';

export const convertProductEnvToEnvironmentName = (
  env: ProductEnv,
  personalEnv: PersonalEnv,
  isCommonBuild: boolean,
) => {
  let environmentName = '';
  if (env === 'dev') {
    if (personalEnv.startsWith('draft-')) {
      environmentName = 'Draft';
    } else {
      environmentName = personalEnv || isCommonBuild ? 'Personal' : 'Common';
    }
  } else if (env === 'staging') {
    environmentName = 'Preview';
  } else if (env === 'rc') {
    environmentName = 'RC';
  } else if (env === 'qa') {
    environmentName = 'QA';
  }

  return environmentName;
};

export const convertPersonalEnvToPersonalName = (
  personalEnv: string,
  isCommonBuild: boolean,
) => {
  if (typeof window !== 'undefined' && isCommonBuild) {
    return location.host.split('.')[0];
  }

  return personalEnv.replace(/^(draft|rc)-/, '');
};
