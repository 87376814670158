import React from 'react';
import { Navic } from 'js/components/ui/Icon/Navic';
import { Pattern } from 'js/components/common/layout/DailyContents/DailyContentsTitle/type';
import { FACE_MAP } from 'js/components/common/layout/DailyContents/DailyContentsTitle/const';

export type Props = {
  playedCount: number;
  contentsCount: number;
  isLogin?: boolean;
};

export const DailyContentsTitlePresenter: React.FC<Props> = ({
  playedCount,
  contentsCount,
  isLogin,
}) => {
  const pattern: Pattern =
    contentsCount === 0
      ? 'loading'
      : !isLogin
        ? 'notLogin'
        : playedCount >= contentsCount
          ? 'completed'
          : playedCount > 0
            ? 'playing'
            : 'notPlayed';

  return (
    <div className="daily-contents-title">
      <h2 className="daily-contents-title__heading">毎日貯まる</h2>
      {pattern !== 'loading' && (
        <>
          <p className="daily-contents-title__message">
            <span className="daily-contents-title__message-wrapper c_underline-block">
              {pattern === 'notLogin' ? (
                <>
                  ポイント獲得には
                  <br />
                  <a href="/login/">ログイン</a>が必要です
                </>
              ) : pattern === 'completed' ? (
                <em>
                  おめでとう！
                  <br />
                  全部達成したよ！
                </em>
              ) : pattern === 'playing' ? (
                <>
                  {contentsCount}個のうち<em>{playedCount}個</em>を完了！
                </>
              ) : (
                <>
                  スキマ時間でポイ活！
                  <br />
                  コツコツ貯めよう！
                </>
              )}
            </span>
          </p>
          <Navic
            className="daily-contents-title__navic"
            faceType={FACE_MAP[pattern]}
            direction="left"
          />
        </>
      )}
    </div>
  );
};
