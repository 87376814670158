import React from 'react';
import classNames from 'classnames';
import {
  BannerSize,
  BannerPosition,
  Overlay,
  PrColor,
} from 'js/components/ui/PrBanner/type';
import { useAppendAd } from 'js/hooks/useAppendAd';

export type Props = {
  size: BannerSize;
  adInnerHtml: string;
  hasBorder?: boolean;
  position?: BannerPosition;
  overlay?: Overlay;
  prColor?: PrColor;
  className?: string;
  style?: React.CSSProperties;
};

export const PrBannerPresenter: React.FC<Props> = ({
  size,
  adInnerHtml,
  hasBorder,
  position,
  overlay,
  prColor = 'black',
  className,
  style,
}) => {
  const { appendElementRef } = useAppendAd<HTMLDivElement>(adInnerHtml);
  return (
    <div
      className={classNames(
        'c_adnw',
        `c_${size}`,
        {
          'has-border': hasBorder,
          [`position-${position}`]: position,
          [`overlay-${overlay}`]: overlay,
        },
        className,
      )}
      {...{ style }}
    >
      <div ref={appendElementRef} className="c_adnw__ad-wrapper" />
      {!overlay && (
        <span
          className={classNames('c_adnw__pr', {
            'color-white': prColor === 'white',
          })}
        >
          この広告はECナビポイント加算対象外です。
        </span>
      )}
    </div>
  );
};
