import React from 'react';
import classNames from 'classnames';

export type Props = {
  icon: 'information' | 'mypage' | 'history' | 'help' | 'guide' | 'logout';
  name: string;
  href: string;
  callback?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const MyMenuShortcutItemPresenter: React.FC<Props> = ({
  icon,
  name,
  href,
  callback,
}) => (
  <li className="new-my-menu-shortcut__item u_grid__item">
    <a
      className={classNames([
        'new-my-menu-shortcut__link',
        'c_blocklink',
        `c_hover-icon icon-${icon}`,
      ])}
      href={href}
      onClick={callback}
    >
      {name}
    </a>
  </li>
);
