import React from 'react';
import { Point } from 'js/components/common/Point/Point';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

const minimumExchangePoint = 3000;

export type Props = {
  totalPoint: number;
  isVisible?: boolean;
};

export const MyMenuPointProgressPresenter: React.FC<Props> = ({
  totalPoint,
  isVisible = false,
}) =>
  totalPoint < minimumExchangePoint ? (
    <div className="new-my-menu-point__progress progress-circle">
      <CircularProgressbarWithChildren
        maxValue={minimumExchangePoint}
        value={isVisible ? totalPoint : 0}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#F66',
          trailColor: '#FFE5E5',
          pathTransition: isVisible
            ? 'stroke-dashoffset 1.5s cubic-bezier(.215, .61, .355, 1) 0s'
            : 'stroke-dashoffset 0s 0.3s',
        })}
      >
        <p className="new-my-menu-point__progress-text">
          ポイント交換
          <br />
          できるまで
          <br />
          <Point
            point={minimumExchangePoint - totalPoint}
            size="small"
            style="normal"
          />
        </p>
      </CircularProgressbarWithChildren>
    </div>
  ) : (
    <a
      className="new-my-menu-point__exchange c_reset c_hover-icon"
      href="https://ecnavi.jp/mypage/ex_select/"
    >
      ポイント交換
      <br />
      できます
    </a>
  );
