import React, { BaseSyntheticEvent } from 'react';
import classNames from 'classnames';

export type SizeType = 'small' | 'medium';
export type PositionType = 'top-right' | 'right';

export type Props = {
  handleClose: (e: Event | BaseSyntheticEvent) => void;
  size?: SizeType;
  position?: PositionType;
};

export const CloseButtonPresenter: React.FC<Props> = ({
  handleClose,
  size = 'medium',
  position = 'top-right',
}) => (
  <button
    className={classNames(
      'c_close-button',
      `c_size-${size}`,
      `c_position-${position}`,
    )}
    type="button"
    onClick={handleClose}
  />
);
