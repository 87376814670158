import React from 'react';
import { TopicPath } from 'js/components/common/layout/TopicPath/type';

export type Props = {
  topicPath: TopicPath[];
};

export const TopicPathPresenter: React.FC<Props> = ({ topicPath }) => (
  <>
    <div className="c_topic-path">
      <ul
        className="c_topic-path__list"
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        <li
          className="c_topic-path__item"
          itemScope
          itemProp="itemListElement"
          itemType="http://schema.org/ListItem"
        >
          <a
            className="c_topic-path__link"
            itemProp="item"
            href="https://ecnavi.jp/"
          >
            <span className="c_underline" itemProp="name">
              ホーム
            </span>
          </a>
          &nbsp;
          <meta itemProp="position" content="1" />
        </li>
        {topicPath.map((topic, index) => (
          <li
            key={topic.text}
            className="c_topic-path__item"
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            &nbsp;
            {topic.href ? (
              <a
                className="c_topic-path__link c_underline"
                itemProp="item"
                href={topic.href}
              >
                <span itemProp="name">{topic.text}</span>
              </a>
            ) : (
              <span itemProp="name">{topic.text}</span>
            )}
            &nbsp;
            <meta itemProp="position" content={(index + 2).toString()} />
          </li>
        ))}
      </ul>
    </div>
  </>
);
