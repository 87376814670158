import React from 'react';
import {
  TopicPathPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/TopicPath/presenter';

type Props = PresenterProps;

export const TopicPath: React.FC<Props> = ({ topicPath }) => (
  <TopicPathPresenter topicPath={topicPath} />
);
