import React from 'react';
import {
  NotificationDotPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Notification/NotificationDot/presenter';

export type Props = PresenterProps;

export const NotificationDot: React.FC<Props> = ({ className, isSmall }) => (
  <NotificationDotPresenter className={className} isSmall={isSmall} />
);
