import React from 'react';

export const SnsLinkPresenter: React.FC = () => (
  <ul className="sns-link-list clearfix">
    <li className="sns-link-list__item">
      <a
        href="https://www.instagram.com/ecnavi_official/"
        target="_blank"
        rel="noopener noreferrer"
        className="sns-link-list__link instagram-link c_reset"
      >
        Instagram
      </a>
    </li>
    <li className="sns-link-list__item">
      <a
        href="https://twitter.com/ecnavi"
        target="_blank"
        rel="noopener noreferrer"
        className="sns-link-list__link twitter-link c_reset"
      >
        X
      </a>
    </li>
  </ul>
);
