import React from 'react';
import classNames from 'classnames';
import { IconType } from 'js/types/icon';

export type Props = {
  icon: IconType;
  label?: string;
  className?: string;
  fontSize?: string;
  isInline?: boolean;
};

export const IconPresenter: React.FC<Props> = ({
  icon,
  label,
  className,
  fontSize,
  isInline,
}) => (
  <span
    className={classNames(
      'c_icon',
      { 'is-inline': isInline },
      `c_icon-${icon}`,
      className,
    )}
    style={{ fontSize }}
  >
    {label}
  </span>
);
