import axiosRetry, {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from 'axios-retry';
import axios, { AxiosError } from 'axios';
import { ENVIRONMENTS } from 'js/const/env/env';
import { StatusCode } from 'js/components/common/layout/Error/type';
import { HTTP_STATUS_ERROR_MESSAGE_MAP } from 'js/components/common/layout/Error/const';

const maxRetryCount = 1;
const defaultTimeout = ENVIRONMENTS.PRODUCT_ENV === 'prod' ? 500 : 5000;
const lastTimeout = 0;

const client = axios.create({
  timeout: defaultTimeout,
});
axiosRetry(client, {
  retries: maxRetryCount,
  shouldResetTimeout: true,
  retryCondition: (error) =>
    isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED',
  retryDelay: (retryCount, error) => {
    if (retryCount >= maxRetryCount) {
      // eslint-disable-next-line no-param-reassign
      error.config.timeout = lastTimeout;
    }
    return exponentialDelay(retryCount);
  },
});
export { client };

export type HttpErrorObject = {
  name: string;
  statusCode: number;
  statusText: string;
  message: string;
  url: string;
};

export class HttpError extends AxiosError {
  name: string;

  statusCode: number;

  statusText: string;

  message: string;

  url: string;

  constructor(error: AxiosError) {
    super(
      error.message,
      error.code,
      error.config,
      error.request,
      error.response,
    );

    this.name = 'HttpError';
    this.statusCode = error.response?.status ?? 0;
    this.statusText = error.response?.statusText ?? '';
    this.message = error.message ?? '';
    this.url = error.config.url ?? '';
  }

  serialize(): HttpErrorObject {
    return {
      name: this.name,
      message: this.message,
      statusCode: this.statusCode,
      statusText: this.statusText,
      url: this.url,
    };
  }
}

export abstract class HttpErrorFactory {
  static create(statusCode: StatusCode): HttpError {
    const error = new AxiosError(
      HTTP_STATUS_ERROR_MESSAGE_MAP[statusCode].message,
      statusCode.toString(),
      {},
      {},
      {
        data: { message: HTTP_STATUS_ERROR_MESSAGE_MAP[statusCode].message },
        status: statusCode,
        statusText: HTTP_STATUS_ERROR_MESSAGE_MAP[statusCode].status,
        headers: {},
        config: {},
      },
    );

    return new HttpError(error);
  }
}

export const isAxiosError = <Response>(
  error: unknown,
): error is AxiosError<Response> => axios.isAxiosError(error);
