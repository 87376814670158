import React from 'react';
import {
  PointPatternPresenter,
  Props as PresenterProps,
} from 'js/components/common/Point/PointPattern/presenter';

export type Props = PresenterProps;

export const PointPattern: React.FC<Props> = ({
  regularPoint,
  campaignPoint,
  pointText,
  beforeSize,
  afterSize,
  unit,
  sizeType,
  isCondensed,
  isGradeUpCount,
  baseClassName,
}) => (
  <PointPatternPresenter
    regularPoint={regularPoint}
    campaignPoint={campaignPoint}
    pointText={pointText}
    beforeSize={beforeSize}
    afterSize={afterSize}
    unit={unit}
    sizeType={sizeType}
    isCondensed={isCondensed}
    isGradeUpCount={isGradeUpCount}
    baseClassName={baseClassName}
  />
);
