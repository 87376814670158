import React from 'react';
import {
  MyMenuShortcutItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/MyMenuShortcutItem/presenter';

export type Props = PresenterProps;

export const MyMenuShortcutItem: React.FC<Props> = ({
  icon,
  name,
  href,
  callback,
}) => (
  <MyMenuShortcutItemPresenter
    icon={icon}
    name={name}
    href={href}
    callback={callback}
  />
);
